<template>
  <div class="pageContol listWrap ProofOfClassHours">
    <div class="framePage">
      <!-- <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">班级列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">快速开班</a>
        </span>
      </div> -->
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="ProofOfClassHours_1" v-if="listDataInfo.postName">
              <div class="ProofOfClassHours_1_1">岗位类型：</div>
              <div class="ProofOfClassHours_1_2">{{ listDataInfo.postName }}</div>
            </div>
            <div class="ProofOfClassHours_1" v-if="listDataInfo.industryNamePath">
              <div class="ProofOfClassHours_1_1">行业类型：</div>
              <div class="ProofOfClassHours_1_2">{{ listDataInfo.industryNamePath }}</div>
            </div>
            <div class="ProofOfClassHours_1" v-if="listDataInfo.trainLevelName">
              <div class="ProofOfClassHours_1_1">培训类型：</div>
              <div class="ProofOfClassHours_1_2">{{ listDataInfo.trainLevelName }}</div>
            </div>
            <div class="ProofOfClassHours_1">
              <div class="ProofOfClassHours_1_1">视频时长：</div>
              <div class="ProofOfClassHours_1_2">{{ listDataInfo.kpointDurationTotal }}</div>
            </div>
            <div class="ProofOfClassHours_1">
              <div class="ProofOfClassHours_1_1">总学时：</div>
              <div class="ProofOfClassHours_1_2">{{ listDataInfo.studyLessonNumTotal }}</div>
            </div>
            <div class="ProofOfClassHours_2">
              <div class="ProofOfClassHours_2_1">课节信息</div>
              <div class="ProofOfClassHours_2_2">视频时长</div>
              <div class="ProofOfClassHours_2_3">讲师</div>
            </div>
            <div class="ProofOfClassHours_3" v-for="(item, index) in listDataInfo.sectionList" :key="index">

              <div class="ProofOfClassHours_title">{{ item.sectionName }}</div>
              <div class="ProofOfClassHours_3_1" v-for="(item1, index1) in item.kpointList" :key="index1">
                <div class="ProofOfClassHours_3_1_1">{{ item1.kpointName }}</div>
                <div class="ProofOfClassHours_3_1_2">{{ item1.kpointDurationStr }}</div>
                <div class="ProofOfClassHours_3_1_3">{{ item1.teacherName }}</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import List from "@/mixins/List";
export default {
  name: "addClassCtr",
  components: {},
  data() {
    return {
      // 列表数据集合
      listDataInfo: {

      },
    };
  },
  mixins: [List],
  created() {
    this.getList(this.$route.query.projectId);
  },
  mounted() { },
  methods: {
    getList(projectId) {
      this.$post("/biz/projectCourseOutline/getProjectCourseInfo", { projectId }).then((res) => {
        console.log(res)
        this.listDataInfo = res.data || {};
      });
    },
  },
};
</script>
<style lang="less" scoped>
.framePage {
  padding: 0;
}

.ovy-a {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0 !important
  }
}

.ProofOfClassHours {
  font-size: 16px;

  .ProofOfClassHours_1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 10px;

    .ProofOfClassHours_1_1 {
      width: 80px;
      flex-shrink: 0;
    }

    .ProofOfClassHours_1_2 {
      width: 100%;
    }
  }

  .ProofOfClassHours_2 {
    display: flex;
    margin-bottom: 10px;

    .ProofOfClassHours_2_1 {
      width: 50%;
    }

    .ProofOfClassHours_2_2 {
      width: 30%;
    }

    .ProofOfClassHours_2_3 {
      width: 20%;
    }
  }

  .ProofOfClassHours_3 {
    margin-bottom: 20px;
    background: #f2f2f2;
    padding: 6px;
    border-radius: 4px;

    .ProofOfClassHours_title {
      font-weight: 600;
    }

    .ProofOfClassHours_3_1 {
      margin-top: 10px;
      display: flex;

      .ProofOfClassHours_3_1_1 {
        width: 50%;
        word-wrap: break-word;
      }

      .ProofOfClassHours_3_1_2 {
        width: 30%;
      }

      .ProofOfClassHours_3_1_3 {
        width: 20%;
      }
    }
  }
}
</style>
